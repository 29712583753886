import React from 'react';
import screenshot from 'assets/screenshot-ubl.png';

class Ubl extends React.Component {

  render() {
    return (
      <div className="project__wrapper">
        <div className="project__body">
          <h2 className="project__title">Ultimate Bucketlist - Custom Wordpress Travel Blog</h2>
          <p className="project__body-text">
            <span className="bold">End Goal: </span>Build a custom Wordpress theme and blog.<br/>
            <span className="bold">Purpose: </span>To design, develop, host and deliver a blog built on a CMS to the client.<br/>
            <span className="bold">Technologies Used: </span>Wordpress, PHP, MySQL<br/>
            <span className="bold">Links: </span><a target="_blank" rel="noopener noreferrer" href="https://web.archive.org/web/20161022174715/http://www.the-ultimatebucketlist.com/">Demo</a>
          </p>
          <p className="project__body-text">
            The Ultimate Bucketlist is a travel blog I built for my first freelance client.
          </p>
          <p className="project__body-text">
            It was built on a CMS (Wordpress) to allow the client to easily create blog posts without modifying code.
          </p>
          <p className="project__body-text">
            The site was designed, built, deployed and delivered in one month (including the creation of multiple custom Wordpress plugins and a usage tutorial video).
          </p>
          <img className="project__feature-image" src={screenshot} alt="Ultimate Bucketlist Site Screenshot" />
        </div>
      </div>
    );
  }

}

export default Ubl;