import React from 'react';
import screenshot from 'assets/screenshot-mreddit.png';

class Mreddit extends React.Component {

  render() {
    return (
      <div className="project__wrapper">
        <div className="project__body">
          <h2 className="project__title">Minimal Reddit - Reddit Reimagined for Desktop</h2>
          <p className="project__body-text">
            <span className="bold">End Goal: </span>Build a version of Reddit that maximises the utility of large screens.<br/>
            <span className="bold">Purpose: </span>To familiarize myself with the Angular framework.<br/>
            <span className="bold">Technologies Used: </span>Angular, RxJs, Firebase<br/>
            <span className="bold">Links: </span><a target="_blank" rel="noopener noreferrer" href="https://mreddit-389fb.firebaseapp.com/">Demo</a>
          </p>
          <p className="project__body-text">
            Reddit is among the ten most visited websites in the world.<br/>
          </p>
          <p className="project__body-text">
            It also, unfortunately, has one of the least user-friendly interfaces I've seen, especially on Desktop.
            Minimal Reddit was my attempt at fixing that, and arguably was a major catalyst in my conversion from UI/UX to frontend developer.
          </p>
          <img className="project__feature-image" src={screenshot} alt="Ultimate Bucketlist Site Screenshot" />
        </div>
      </div>
    );
  }

}

export default Mreddit;