import Flexcast from "projects/Flexcast/Flexcast";
import Ubl from "projects/Ubl/Ubl";
import Mreddit from "projects/Mreddit/Mreddit";
import Passivize from "projects/Passivize/Passivize";
import Intellicover from "projects/Intellicover/Intellicover";

export const projectData = [
  {
    id: 1,
    name: 'Flex Cast - An Open Source Podcast Client',
    nameShort: 'Flex Cast',
    component: Flexcast,
    description: [
      {
        heading: 'Type',
        value: 'React Web App'
      },
      {
        heading: 'Duration',
        value: 'May 2019 - Present'
      },
      {
        heading: 'Purpose',
        value: 'Personal Project'
      }
    ]
  },
  {
    id: 2,
    name: 'Minimal Reddit - Reddit Reimagined for Desktop',
    nameShort: 'Minimal Reddit',
    component: Mreddit,
    description: [
      {
        heading: 'Type',
        value: 'Angular Web App'
      },
      {
        heading: 'Duration',
        value: 'Dec 2017 - Dec 2017'
      },
      {
        heading: 'Purpose',
        value: 'Personal Project'
      }
    ]
  },
  {
    id: 3,
    name: 'Passivize - A Blog About Passive Income',
    nameShort: 'Passivize',
    component: Passivize,
    description: [
      {
        heading: 'Type',
        value: 'Wordpress Website'
      },
      {
        heading: 'Duration',
        value: 'Jun 2017 – Sep 2017'
      },
      {
        heading: 'Purpose',
        value: 'Personal Project'
      }
    ]
  },
  {
    id: 4,
    name: 'Ultimate Bucketlist - Wordpress Travel Blog',
    nameShort: 'Ultimate Bucketlist',
    component: Ubl,
    description: [
      {
        heading: 'Type',
        value: 'Wordpress Website'
      },
      {
        heading: 'Duration',
        value: 'Dec 2015 – Dec 2015'
      },
      {
        heading: 'Purpose',
        value: 'Freelance Project'
      }
    ]
  },
  {
    id: 5,
    name: 'IntelliCover - Android Device Automation',
    nameShort: 'IntelliCover',
    component: Intellicover,
    description: [
      {
        heading: 'Type',
        value: 'Android Application'
      },
      {
        heading: 'Duration',
        value: 'Aug 2012 – Jan 2013'
      },
      {
        heading: 'Purpose',
        value: 'Personal Project'
      }
    ]
  }
]