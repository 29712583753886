import React from 'react';
import './Project.scss';
import { Switch, Redirect, Route } from 'react-router-dom';
import { projectData } from 'constants/projects';

class Project extends React.Component {

  render() {
    return (
      <div className={ 'project ' + (!this.props.showProject && 'project--collapsed') }>
        <div className="project__content">
          <Switch>
            { projectData.map((project) =>
              <Route key={ project.id } path={'/projects/' + project.id} component={ project.component }/>
            )}
            <Redirect to="/projects/1"></Redirect>
          </Switch>
        </div>
        <div className="project__close-button" onClick={ this.props.changeProjectState }>
          <button>CLOSE</button>
        </div>
      </div>
    );
  }

}

export default Project;