import React from 'react';
import screenshot from 'assets/screenshot-intellicover.jpg';

class Intellicover extends React.Component {

  render() {
    return (
      <div className="project__wrapper">
        <div className="project__body">
          <h2 className="project__title">Minimal Reddit - Reddit Reimagined for Desktop</h2>
          <p className="project__body-text">
            <span className="bold">End Goal: </span>Build and maintain a real world product and put it on the Play Store.<br/>
            <span className="bold">Purpose: </span>To build a real world product and put it on the Play Store.<br/>
            <span className="bold">Technologies Used: </span>Android SDK<br/>
            <span className="bold">Links: </span><a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.dragonnis.intellicover&hl=en">Play Store</a>
          </p>
          <p className="project__body-text">
            Intellicover was my first widely published project, and one that truly cemented in me the dream to build software.
          </p>
          <p className="project__body-text">
            It was essentially created to perform the task of modern "smart flip covers" before they existed. It used the proximity sensor 
            and accelerometer to detect when a phone was put in a pocket or placed upside down on a table, or when a flip cover was closed. It would then 
            lock the phone, and automatically unlock it once the phone was picked up.
          </p>
          <p className="project__body-text">
            In a year, the application (and its successor) received almost 100,000 total downloads, held a 3.9 star rating, and had been featured on 
            Discovery Channel Canada.
          </p>
          <p className="project__body-text">
            More importantly, I received messages and videos from users with motor impairments claiming an application I made in my hostel room 
            as a fun little project had actually helped to make a positive impact in their lives.
          </p>
          <img className="project__feature-image" src={screenshot} alt="Intellicover App Screenshot" style={{width: '250px'}}/>
          <p className="project__body-text">
            All of this, back in the days when Android 2.3 was knocking on Apple's door and Android Studio was a distant pipe dream. After mucking around for
            months with custom ROMs and Kernels, I just happened to find my way to app development, and it actually made a difference to someone
            somewhere in the world.
          </p>
          <p className="project__body-text">
            That's when I knew software engineering - making something out of nothing - was for me.
          </p>
        </div>
      </div>
    );
  }

}

export default Intellicover;