import React from 'react';
import './MenuButton.scss';

const MenuButton = (props) => {
  return (
    <button className="menu-button" onClick={ props.changeSidebarState }>
      <div className="menu-button__line"></div>
      <div className="menu-button__line"></div>
      <div className="menu-button__line"></div>
    </button>
  );
}

export default MenuButton;