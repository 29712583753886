import React from 'react';
import screenshot from 'assets/screenshot-passivize.png';

class Passivize extends React.Component {

  render() {
    return (
      <div className="project__wrapper">
        <div className="project__body">
          <h2 className="project__title">Minimal Reddit - Passivize - A Blog About Passive Income</h2>
          <p className="project__body-text">
            <span className="bold">End Goal: </span>Build a Wordpress blog and create video, text and graphic content for it.<br/>
            <span className="bold">Purpose: </span>To educate the world about passive income and learn how to start an online business!<br/>
            <span className="bold">Technologies Used: </span>Wordpress, PHP, MySQL<br/>
            <span className="bold">Links: </span><a target="_blank" rel="noopener noreferrer" href="https://passivize.com">Demo</a>
          </p>
          <p className="project__body-text">
            Passivize is an ambitious project to spread the idea of building digital assets and long-term passive income.
          </p>
          <p className="project__body-text">
            I built a blog, ran a Youtube channel, wrote articles, created infographics, and more. And in the process, I learned a couple very important things:
          </p>
          <p className="project__body-text">
            1. Heed the 80/20 principle: 80% of the outcome comes from 20% of the effort, so direct your effort wisely.
          </p>
          <p className="project__body-text">
            2. You can't do everything by yourself. Delegation is key to growth.
          </p>
          <img className="project__feature-image" src={screenshot} alt="Passivize Site Screenshot" />
        </div>
      </div>
    );
  }

}

export default Passivize;