import React from 'react';
import './Sidebar.scss';
import { projectData } from 'constants/projects';
import { NavLink } from 'react-router-dom';
import resume from 'assets/resume.pdf';

class Sidebar extends React.Component {

  render() {
    return (
      <div className={ 'sidebar ' + (this.props.sidebarCollapsed && 'sidebar--collapsed') }>
        <div className="sidebar__content">
          <p className="sidebar__heading">Projects</p>
          <ul className="sidebar__list">
            { projectData.map((project) =>
              <li key={ project.id } className="sidebar__list-item">
                <NavLink className="sidebar__link" to={'/projects/' + project.id} activeClassName="sidebar__link--active">{ project.nameShort }</NavLink>
              </li>
            )}
          </ul>
          <p className="sidebar__heading">About</p>
          <ul className="sidebar__list">
            <li className="sidebar__text">I'm a web developer based in Toronto specializing in modern JavaScript. I've been building software for almost a decade,
            and I'm deeply passionate about web technologies and my work. If you'd like to know more, 
            please <a className="sidebar__link--active" href={resume}>click here to download my resume.</a></li>
          </ul>
          <p className="sidebar__heading">Contact/Links</p>
          <ul className="sidebar__list">
            <li className="sidebar__social">
              <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/in/jasjitsm/" className="sidebar__social-single"><i className="fab fa-linkedin-in"></i></a>
              <a target="_blank" rel="noopener noreferrer" href="https://github.com/jasjitsm" className="sidebar__social-single"><i className="fab fa-github"></i></a>
              <a target="_blank" rel="noopener noreferrer" href="&#109;&#65;&#73;&#108;&#84;&#111;&#58;&#106;&#97;&#115;&#106;&#105;&#116;&#115;&#109;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;" className="sidebar__social-single"><i className="far fa-envelope"></i></a>
              <a target="_blank" rel="noopener noreferrer" href={resume} className="sidebar__social-single"><i className="far fa-file-pdf"></i></a>
            </li>
          </ul>
        </div>
      </div>
    );
  }

}

export default Sidebar;