import React from 'react';
import './Main.scss';
import MenuButton from 'components/MenuButton/MenuButton';
import { projectData } from 'constants/projects';
import { NavLink, withRouter } from 'react-router-dom';

class Main extends React.Component {

  state = {
    activeProjectData: projectData[0]
  }

  componentDidMount() {
    this.updateCardContent();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params !== prevProps.match.params) {
      this.updateCardContent();
    }
  }

  updateCardContent = () => {
    const urlArr = this.props.location.pathname.split('/');
    let projectIndex = urlArr[urlArr.indexOf('projects') + 1];
    if (projectIndex === '') projectIndex = 1;
    if(projectData[projectIndex-1] === undefined) return ;
    const activeProjectData = projectData[projectIndex-1];
    if(activeProjectData.id !== this.state.activeProjectData.id) {
      document.querySelector('.card').classList.add('card--fade');
      setTimeout(() => {
        this.setState({ activeProjectData }, () => {
          document.querySelector('.card').classList.remove('card--fade');
        });
      }, 300);
    }
  }

  renderCard() {
    return (
      <div className="card">
        <div className="card__content">
          <div className="card__header">
            <div className="card__notches">
              <div className="card__notch"></div>
              <div className="card__notch"></div>
            </div>
            <div className="card__header-text-wrapper">
              <p className="card__project-name">Project Name</p>
              <h2 className="card__project-title">{ this.state.activeProjectData.name }</h2>
            </div>
          </div>

          <div className="card__body">
            { this.state.activeProjectData.description.map(description => {
              return (
                <div className="card__body-item" key={description.heading}>
                  <p className="card__body-heading">{ description.heading }</p>
                  <p className="card__body-value">{ description.value }</p>
                </div>
              );
            })}
          </div>

          <div className="card__footer">
            <button className="card__more-button" onClick={ this.props.changeProjectState }>See Project</button>
            <ul className="card__navigation">
              { projectData.map((project) =>
                <NavLink key={ project.id } className="card__navigation-item" to={'/projects/' + project.id} activeClassName="card__navigation-item--active"></NavLink>
              )}
            </ul>
          </div>
        </div>
        <div className="card__image"></div>
      </div>
    )
  }

  renderName() {
    return (
      <div className="name">
        <h1 className="name__text">Jasjit Singh Marwah</h1>
        <p className="name__occupation">Web Developer</p>
      </div>
    )
  }

  render() {
    return (
      <div className={ 'main ' + (this.props.mainCollapsed && 'main--collapsed') }>
        { this.renderCard() }
        <div className="main__left-panel">
          <MenuButton changeSidebarState={ this.props.changeSidebarState }></MenuButton>
          { this.renderName() }
        </div>
        <div className="main__right-panel"></div>
      </div>
    );
  }

}

export default withRouter(Main);