import React from 'react';
import screenshot from 'assets/screenshot-flexcast.png';

class Flexcast extends React.Component {

  render() {
    return (
      <div className="project__wrapper">
        <div className="project__body">
          <h2 className="project__title">Flex Cast - An Open Source Podcast Player</h2>
          <p className="project__body-text">
            <span className="bold">End Goal: </span>To build a multi-platform podcast player using web technologies.<br/>
            <span className="bold">Purpose: </span>To familiarize myself with the React library.<br/>
            <span className="bold">Technologies Used: </span>React, SCSS, iTunes API<br/>
            <span className="bold">Links: </span><a target="_blank" rel="noopener noreferrer" href="https://github.com/jasjitsm/flexcast">Github Repo</a>
          </p>
          <p className="project__body-text">
            Flex Cast is my first attempt at creating a moderately large scale application with React.
          </p>
          <p className="project__body-text">
            The idea was born out of a dissatisfaction with Spotify's podcast player, and a curiosity of the technologies (or lack thereof) used to
            parse and collect the wide, decentralized network of podcast media.
          </p>
          <img className="project__feature-image" src={screenshot} alt="Flexcast Screenshot" />
          {/* <p className="project__body-text">
            A discovery I made early on is that Apple's podcast repository single-handedly propelled the podcast format to success. The most widely accepted
            XML format for tagging and adding metadata to podcasts is Apple's.
          </p>
          <p className="project__body-text">
            Apart from a few companies like PocketCast which maintain a server just for crawling the web for RSS feeds, the industry in general
            seems to rely heavily on Apple for providing the most up-to-date feed data.
          </p>
          <p className="project__body-text">
            Using the iTunes API, then, seemed like the logical choice.
          </p>
          <p className="project__body-text">
            This project is still fairly new and currently in progress so there's not much else to comment on.
            So far I'm still building out the UI and figuring out how to parse feeds after retrieving links to them from the iTunes API.
          </p>
          <p className="project__body-text">
            It's a ways down the road, but I plan to eventually morph Flex Cast into an Electron-based desktop application.
          </p> */}
        </div>
      </div>
    );
  }

}

export default Flexcast;