import React from 'react';
import './App.scss';
import Sidebar from 'containers/Sidebar/Sidebar';
import Main from 'containers/Main/Main';
import Project from 'containers/Project/Project';
import { projectData } from 'constants/projects';
import { BrowserRouter } from 'react-router-dom';

class App extends React.Component {

  state = {
    sidebarCollapsed: true,
    mainCollapsed: false,
    showProject: false,
    projectData: projectData
  }

  componentDidMount() {
    const url = new URL(window.location);
    const showProject = url.searchParams.get('showProject');
    if (showProject) {
      this.changeProjectState();
    }
  }

  changeProjectState = () => {
    this.setState({ showProject: !this.state.showProject }, () => {
      if (this.state.showProject === true) {
        this.setState({ sidebarCollapsed: true, mainCollapsed: true });
      }
      else {
        this.setState({ mainCollapsed: false });
      }
    });
  }

  changeSidebarState = () => {
    this.setState({ sidebarCollapsed: !this.state.sidebarCollapsed , showProject: false, mainCollapsed: false});
  }

  render() {
    return (
      <div className="app">
        <div className="mobile-overlay">
          Whoops! Sorry, this web site hasn't been optimised for mobile yet.<br/><br/>
          I'm working on it! In the meanwhile, please use a computer instead.
        </div>
        <BrowserRouter>
          <Sidebar sidebarCollapsed={ this.state.sidebarCollapsed }></Sidebar>
          <Main mainCollapsed={ this.state.mainCollapsed } changeSidebarState={ this.changeSidebarState } changeProjectState={ this.changeProjectState }></Main>
          <Project showProject={ this.state.showProject } changeProjectState={ this.changeProjectState }></Project>
        </BrowserRouter>
      </div>
    );
  }

}

export default App;
